import React, { useCallback, useEffect, useState } from "react"
import * as styles from "./hiddenPage.module.css"
import NewBadge from "components/tools/newBadge"
import Pagination from "components/tools/pagination.js"
import Layout from "../../components/hiddenLayout.js"
import { navigate } from "gatsby"
import Detail from "./detail"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
  getHiddenProjectListAsync,
  getHiddenStoryListAsync,
} from "store/modules/hiddenReducer"
import { getThumb } from "utils"
import { HIDDENPAGE } from "utils/data"
import queryString from "query-string"
import api from "apis"

const IndexPage = props => {
  const [order, setOrder] = useState(1)
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 16

  const [project, setProject] = useState({})

  const [paramsPj, paramsDt] = props["*"].split("/")
  const { hidden, story } = useSelector(
    ({ hiddenReducer: { data } }) => data,
    shallowEqual
  )

  const { pageList, pageNum, total } = story.list

  const dispatch = useDispatch()

  const getHiddenList = useCallback(
    (page, take, order) =>
      dispatch(getHiddenProjectListAsync(page, take, order)),
    [dispatch]
  )

  const getStoryList = useCallback(
    (page, take, order, number) =>
      dispatch(getHiddenStoryListAsync(page, take, order, number)),
    [dispatch]
  )
  console.log(hidden.list)
  useEffect(() => {
    ;(async () => {
      getHiddenList(currentPage, pagePostSize, order)
    })()
  }, [currentPage])

  useEffect(async () => {
    if (paramsPj) {
      if (hidden.list.length > 0) {
        const [project] = hidden.list.filter(e => e.link === paramsPj)
        if (project) {
          getStoryList(currentPage, pagePostSize, order, project.hidden_no)
          setProject(project)
        } else {
          alert("입력하신 프로젝트가 존재하지않습니다.")
        }
      }
    } else {
      alert("프로젝트명을 정확히 입력해주세요")
    }
  }, [hidden, paramsDt])

  let title = hidden.list.filter(e => e.link === paramsPj)[0]?.title
  useEffect(() => {
    if (story.detail === HIDDENPAGE.server_error) {
      alert("없는 스토리입니다.")
    } else if (story.detail === HIDDENPAGE.user_error) {
      alert("스토리번호는 숫자만 입력가능합니다.")
    }
  }, [story])

  return (
    <>
      {title &&
      story.detail !== HIDDENPAGE.server_error &&
      story.detail !== HIDDENPAGE.user_error ? (
        <Layout
          src={getThumb(project.thumb_logo_uuid)}
          intro={project.introText}
          pageList={pageList}
        >
          {!paramsDt ? (
            <main className={styles.con}>
              <div className={styles.contents}>
                <div>
                  <div className={styles.pj_title}>
                    <div>{title}</div>
                    <div>
                      (전체{" "}
                      <span className={styles.pink}>{pageList?.length}</span>개
                      스토리)
                    </div>
                  </div>
                  <div className={styles.card_box}>
                    {pageList?.map(e => (
                      <div key={e.story_no} className={styles.card}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "250px",
                          }}
                        >
                          <div style={{ position: "absolute", right: 0 }}>
                            <NewBadge registerTime={e.registerTime} />
                          </div>
                          <img
                            src={getThumb(e.thumb_title_uuid)}
                            className={styles.card_img}
                            onClick={() => {
                              navigate(`${e.story_no}`)
                            }}
                          />
                        </div>
                        <div>{e.title.slice(0, 35)}</div>
                      </div>
                    ))}
                  </div>
                  <div
                    style={{
                      display: "block",
                    }}
                  >
                    <Pagination
                      total={total}
                      pagePostSize={pagePostSize}
                      pageSize={10}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <Detail
              title={paramsPj}
              story_no={Number(paramsDt)}
              hidden_no={project.hidden_no}
              currentPage={currentPage}
            ></Detail>
          )}
        </Layout>
      ) : null}
    </>
  )
}

export default IndexPage
