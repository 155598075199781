import { navigate } from "gatsby"
import { topic1 } from "images"
import React from "react"
import * as styles from "../pages/pro/hiddenPage.module.css"

const HiddenLayout = props => {
  return props && props.pageList ? (
    <div style={{ minWidth: "1200px" }}>
      <div className={styles.con}>
        <header className={styles.header}>
          <div className={styles.logo_box}>
            <img src={props.src} className={styles.logo} />
          </div>
        </header>
      </div>
      <div>{props.children}</div>
      <footer className={styles.footer}>
        <div>{props.intro}</div>
      </footer>
    </div>
  ) : null
}

export default HiddenLayout
