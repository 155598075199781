// extracted by mini-css-extract-plugin
export var con = "hiddenPage-module--con--2Co-q";
export var box = "hiddenPage-module--box--1R6mk";
export var header = "hiddenPage-module--header--1SeBV";
export var logo_box = "hiddenPage-module--logo_box--xqTKL";
export var logo = "hiddenPage-module--logo--3tDXU";
export var contents = "hiddenPage-module--contents--1x80l";
export var pj_title = "hiddenPage-module--pj_title--1muJY";
export var pink = "hiddenPage-module--pink--1MZZk";
export var card_box = "hiddenPage-module--card_box--3wl-j";
export var card = "hiddenPage-module--card--3bsFC";
export var card_img = "hiddenPage-module--card_img--32zYh";
export var pagi = "hiddenPage-module--pagi--16y_h";
export var footer = "hiddenPage-module--footer--34epB";